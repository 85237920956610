import React, { useMemo, useState } from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { Link, useIntl } from 'gatsby-plugin-intl-v6'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from "framer-motion";
import banner from '../static/images/foundation/fund-banner.png'

export default Fund

function Fund() {
  let pageModel = new PageModelTitle('Ecosystem Fund', 'fund-page')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  const locale = intl.locale

  return (
    <div>
      <div className={'banner-pc container'}>
        <motion.div 
          className={'centent'}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className={'banner-title'}>
            {intl.formatMessage({ id: 'Empowering Web3, ' })}
          </div>
          <div className={'banner-title'}>
            {intl.formatMessage({ id: 'Fueling AB Ecosystem' })}<span>{intl.formatMessage({ id: 'AB Ecosystem' })}</span>
          </div>
          <div className={'banner-sub-title'}>
            {intl.formatMessage({ id: 'The AB Ecosystem Fund is dedicated to supporting global Web3 innovations, driving the growth of a decentralized ecosystem.' })}
          </div>
        </motion.div>
        <motion.div 
          className='img-box'
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <img src={banner} className='banner-img' />
        </motion.div>
      </div>
      <div className='banner-mobile-box'>
        <div className={'banner-mobile container'}>
          <div className={'centent'}>
            <motion.div 
              className={'banner-title'}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              {intl.formatMessage({ id: 'Empowering Web3, Fueling AB Ecosystem' })}<span>{intl.formatMessage({ id: 'AB Ecosystem' })}</span>
            </motion.div>
            <motion.div 
              className={'banner-sub-title'}
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
            >
              {intl.formatMessage({ id: 'The AB Ecosystem Fund is dedicated to supporting global Web3 innovations, driving the growth of a decentralized ecosystem.' })}
            </motion.div>
          </div>
          <motion.div 
            className='img-box'
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <img src={banner} className='banner-img' />
          </motion.div>
        </div>
      </div>

      <div className='about-fund container'>
        <motion.div 
          className={'title'}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {intl.formatMessage({ id: 'About AB Ecosystem Fund' })}
        </motion.div>
        <motion.div 
          className={'main-body'}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          {intl.formatMessage({ id: 'The AB Ecosystem Fund drives Web3 innovation through investment and incubation, fueling ecosystem growth. With strong infrastructure, community support, and liquidity, we empower entrepreneurs in DeFi, cross-chain, payments, and Web3 to build the future with global blockchain leaders.' })}
        </motion.div>
      </div>

      <div className='doc-mail'>
        <div className='container'>
          <div className={'content'}>
            <StaticImage
              alt="message"
              class='icon'
              src="../static/images/foundation/message-square.png"
            ></StaticImage>
            <p className='title'>{intl.formatMessage({ id: 'Build with AB' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'AB provides robust infrastructure, fully supporting ecosystem projects and driving rapid growth.' })}</p>
            <a href="https://docs.ab.org/docs/" target="_blank">{intl.formatMessage({ id: 'Start' })}</a>
          </div>
          <div className={'content'}>
            <StaticImage
              alt="mail"
              class='icon'
              src="../static/images/foundation/mail.png"
            ></StaticImage>
            <p className='title'>{intl.formatMessage({ id: 'Apply for Funding' })}</p>
            <p className='desc'>{intl.formatMessage({ id: 'If you are interested in applying for the AB Ecosystem Fund, please email your project introduction and requirements to fund@ab.org' })}</p>
            <a href='mailto:fund@ab.org'>{intl.formatMessage({ id: 'Contact Us' })}</a>
          </div>
        </div>
      </div>

      {/* <div className='project container'>
        <div className='title'>{intl.formatMessage({ id: 'Ecosystem Projects' })}</div>
        <div className='content-box'>

          <div className='content-item'>
            <div>
              <a href='https://www.murphai.io' target='_blank'>
              <StaticImage
                alt="Murph"
                class='project-icon'
                src="../static/images/foundation/p-murph.png"
              ></StaticImage>
              </a>
              <div>
                <p>Murph</p>
                <div>
                  <a href='https://www.murphai.io' target='_blank'>
                  <StaticImage
                    alt="Murph"
                    class='icon'
                    src="../static/images/foundation/icon-website.png"
                  ></StaticImage>
                  </a>
                  <StaticImage
                    alt="Murph"
                    class='icon'
                    src="../static/images/foundation/icon-x.png"
                  ></StaticImage>
                  <StaticImage
                    alt="Murph"
                    class='icon'
                    src="../static/images/foundation/icon-twitter.png"
                  ></StaticImage>
                </div>
              </div>
            </div>
            <p className='desc'>Building the Premier AI dApp Aggregator on Dfinity</p>
          </div>
          
          <div className='content-item'>
            <div>
              <a href='https://www.domin.foundation/' target='_blank'>
              <StaticImage
                alt="Domin"
                class='project-icon'
                src="../static/images/foundation/p-domin.png"
              ></StaticImage>
              </a>
              <div>
                <p>Domin Network</p>
                <div>
                  <a href='https://www.domin.foundation/' target='_blank'>
                  <StaticImage
                    alt="Domin"
                    class='icon'
                    src="../static/images/foundation/icon-website.png"
                  ></StaticImage>
                  </a>
                  <StaticImage
                    alt="Domin"
                    class='icon'
                    src="../static/images/foundation/icon-x.png"
                  ></StaticImage>
                  <StaticImage
                    alt="Domin"
                    class='icon'
                    src="../static/images/foundation/icon-twitter.png"
                  ></StaticImage>
                </div>
              </div>
            </div>
            <p className='desc'>Onboarding the Next Billion Consumers</p>
          </div>
          
          <div className='content-item'>
            <div>
              <a href='https://www.funton.ai/' target='_blank'>
              <StaticImage
                alt="Funton"
                class='project-icon'
                src="../static/images/foundation/p-funton.png"
              ></StaticImage>
              </a>
              <div>
                <p>Funton.ai</p>
                <div>
                  <a href='https://www.funton.ai/' target='_blank'>
                  <StaticImage
                    alt="Funton"
                    class='icon'
                    src="../static/images/foundation/icon-website.png"
                  ></StaticImage>
                  </a>
                  <StaticImage
                    alt="Funton"
                    class='icon'
                    src="../static/images/foundation/icon-x.png"
                  ></StaticImage>
                  <StaticImage
                    alt="Funton"
                    class='icon'
                    src="../static/images/foundation/icon-twitter.png"
                  ></StaticImage>
                </div>
              </div>
            </div>
            <p className='desc'>Bring the pump.fun to GameFi</p>
          </div>
          
          <div className='content-item'>
            <div>
              <a href='https://memefun.ai/' target='_blank'>
              <StaticImage
                alt="memefun"
                class='project-icon'
                src="../static/images/foundation/p-memefun.png"
              ></StaticImage>
              </a>
              <div>
                <p>memefun</p>
                <div>
                  <a href='https://memefun.ai/' target='_blank'>
                  <StaticImage
                    alt="memefun"
                    class='icon'
                    src="../static/images/foundation/icon-website.png"
                  ></StaticImage>
                  </a>
                  <StaticImage
                    alt="memefun"
                    class='icon'
                    src="../static/images/foundation/icon-x.png"
                  ></StaticImage>
                  <StaticImage
                    alt="memefun"
                    class='icon'
                    src="../static/images/foundation/icon-twitter.png"
                  ></StaticImage>
                </div>
              </div>
            </div>
            <p className='desc'>Create your own memecoin with a few steps</p>
          </div>
          
          <div className='content-item'>
            <div>
              <a href='https://www.owlx.ai/#Home' target='_blank'>
              <StaticImage
                alt="OELX"
                class='project-icon'
                src="../static/images/foundation/p-oelx.png"
              ></StaticImage>
              </a>
              <div>
                <p>OWLX</p>
                <div>
                  <a href='https://www.owlx.ai/#Home' target='_blank'>
                  <StaticImage
                    alt="OELX"
                    class='icon'
                    src="../static/images/foundation/icon-website.png"
                  ></StaticImage>
                  </a>
                  <StaticImage
                    alt="OELX"
                    class='icon'
                    src="../static/images/foundation/icon-x.png"
                  ></StaticImage>
                  <StaticImage
                    alt="OELX"
                    class='icon'
                    src="../static/images/foundation/icon-twitter.png"
                  ></StaticImage>
                </div>
              </div>
            </div>
            <p className='desc'>Ask OwlX Anything</p>
          </div>

          <div className='content-item no-bg'></div>
          
        </div>
      </div> */}

    </div>
  )
}